<template>
  <div id="root">
    <b-container class="py-5">
      <b-row>
        <b-col>
          <h2 class="text-center">
            EDUCATION
            <hr style="background-color:white;" class="mt-3" />
          </h2>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <main id="app">
            <section class="timeline">
              <ul>
                <li v-for="(info, index) in timeline_data" v-bind:key="index">
                  <span></span>
                  <div>{{ info.school }}</div>
                  <div>{{ info.address }}</div>
                  <div>{{ info.course }}</div>
                  <div class="year">
                    <span>{{ info.yearEnd }}</span>
                    <span>{{ info.yearStart }}</span>
                  </div>
                </li>
              </ul>
            </section>
          </main>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Education",
  created() {},
  data() {
    return {
      timeline_data: [
        {
          school: "Simon Fraser University",
          address: "8888 University Dr, Burnaby, BC",
          course: "M.Sc. in Computing Science",
          yearStart: "2022",
          yearEnd: "2023",
        },
        {
          school: "Simon Fraser University",
          address: "8888 University Dr, Burnaby, BC",
          course: "B.Sc. in Computing Science",
          yearStart: "2018",
          yearEnd: "2022",
        },
        {
          school: "Langara College",
          address: "100 W 49th Ave, Vancouver, BC",
          course: "",
          yearStart: "2017",
          yearEnd: "2018",
        },
      ],
    };
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
$dark-blue: #2e4a62;
$light-blue: #4e9bfa;
$font-color: #fff;

@mixin clearfix {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
@mixin set-border($thickness, $color, $radius) {
  border: $thickness solid $color;
  border-radius: $radius;
}

#root {
  background-color: $dark-blue;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  color: $font-color;
}
main {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px;
  background: $dark-blue;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.timeline {
  padding: 0.01em 1em;
  ul {
    position: relative;
    &::before {
      @include clearfix();
      height: 100%;
    }
  }
  li {
    position: relative;
    margin: 4.5em auto;
    width: fit-content;
    list-style: none;
    line-height: 25px;
    & > span {
      @include clearfix();
      left: -25px;
      height: 110%;
      @include set-border(2px, $font-color, none);
    }
    & > span::before,
    & > span::after {
      @include clearfix();
      width: 14px;
      height: 14px;
      @include set-border(3px, $font-color, 50%);
      left: -7px;
      background: $light-blue;
    }
    & > span::before {
      top: -15px;
    }
    & > span::after {
      top: 100%;
    }
    div {
      &:nth-child(2) {
        font-size: 1.2em;
      }
      &:nth-child(3),
      &:nth-child(4) {
        font-size: 1em;
        font-style: italic;
        color: darken($font-color, 25%);
      }
    }
    .year span {
      position: absolute;
      font-size: 1em;
      left: -85px;
      width: 40px;
      text-align: right;
      &:first-child {
        top: -20px;
      }
      &:last-child {
        top: 100%;
      }
    }
  }
}
</style>