<template>
  <b-navbar class="mx-3" toggleable="lg" type="light" variant="light">
    <b-navbar-brand to="/"><img style="max-width:2em" src="https://img.icons8.com/ios/100/000000/sign-language-c.png"/></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse is-nav id="nav-collapse">
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="/#skills">Skills</b-nav-item>
        <b-nav-item href="/#projects">Projects</b-nav-item>
        <b-nav-item href="/#contact">Contact Me</b-nav-item>
        <b-nav-item href="/files/resume_public.pdf" download>Résumé</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
  .navbar {
    background: rgba(0, 0, 0, 0) !important;
  }
</style>