var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Particles',{attrs:{"id":"tsparticles","options":{
      autoPlay: true,
      background: {
        color: {
          value: '#0d47a1',
        },
        opacity: 0,
      },
      fpsLimit: 60,
      interactivity: {
        detectsOn: 'canvas',
        events: {
          onClick: {
            enable: true,
            mode: 'push',
          },
          onHover: {
            enable: true,
            mode: 'grab',
            parallax: {
              enable: true,
              force: 80,
              smooth: 10,
            },
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40,
          },
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: 'random',
        },
        links: {
          color: 'random',
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        collisions: {
          enable: true,
        },
        move: {
          direction: 'none',
          enable: true,
          outMode: 'bounce',
          random: false,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            value_area: 800,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: 'circle',
        },
        size: {
          random: true,
          value: 5,
        },
      },
      detectRetina: true,
    }}}),_c('b-container',{attrs:{"id":"container"}},[_c('div',{staticClass:"row h-75"},[_c('textra',{staticClass:"mx-2 my-auto col text-center",attrs:{"id":"textra","data":_vm.introWords,"timer":1,"filter":"bottom-top","infinite":true}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }