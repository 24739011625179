<template>
  <div>
    <b-container class="my-4">
      <b-row>
        <b-col>
          <h2 class="text-center" style="">
            SKILLS
            <hr class="basic-hr" />
          </h2>

          <p class="quotes">
            “Without sharpen your weapon; standing on the battlefield would not
            increase your chance of winning.”
          </p>
          <p class="quotes">― Ankit Sahay</p>
        </b-col>
      </b-row>
      <b-row class="my-2 font-weight-light">
        <b-col md="4">
          <ion-icon name="code-slash-outline" class="iconSet1 mx-auto"></ion-icon>
          <h3 class="my-3 text-center">
            Programming
            Languages
          </h3>
          <ul class="py-2">
            <li class="py-1">C++</li>
            <li class="py-1">Java</li>
            <li class="py-1">TypeScript / JavaScript</li>
            <li class="py-1">Python</li>
            <li class="py-1">Go</li>
            <li class="py-1">Haskell</li>
            <li class="py-1">MATLAB</li>
            <li class="py-1">x86 Assembly Language</li>
          </ul>
        </b-col>
        <b-col md="4">
          <ion-icon name="code-working-outline" class="iconSet1 mx-auto"></ion-icon>
          <h3 class="my-3 text-center">Scripting Languages</h3>
          <ul>
            <li class="py-1">SQL</li>
            <li class="py-1">HTML</li>
            <li class="py-1">CSS</li>
          </ul>
        </b-col>
        <b-col md="4">
          <ion-icon name="hammer-outline" class="iconSet1 mx-auto"></ion-icon>
          <h3 class="my-3 text-center">Tools</h3>
          <ul>
            <li class="py-1">Visual Studio Code</li>
            <li class="py-1">Android Studio</li>
            <li class="py-1">Git</li>
            <li class="py-1">Abode Photoshop</li>
            <li class="py-1">Abode Illustrator</li>
            <li class="py-1">Abode After Effect</li>
            <li class="py-1">Maven</li>
            <li class="py-1">Vagrant</li>
            <li class="py-1">MS Word, Excel and PowerPoint</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Abilities",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
.quotes {
  line-height: 140%;
  text-align: center;
}

.iconSet1 {
  color: #30336b;
  font-size: 2.5em;
  display: block;
}
</style>