<template>
  <div id="root1">
    <b-container class="py-5">
      <b-row>
        <b-col>
          <h2 class="text-center">
            CONTACT ME
            <hr class="mt-3" />
          </h2>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="0" md="1"></b-col>
        <b-col cols="6" md="2">
          <a href="https://t.me/Cole9712" target="_blank"
            ><img
              src="https://img.icons8.com/ios-filled/100/000000/telegram-app.png"
          /></a>
        </b-col>
        <b-col cols="6" md="2">
          <a href="https://steamcommunity.com/id/Cole_9712/" target="_blank"
            ><img src="https://img.icons8.com/metro/104/000000/steam.png"
          /></a>
        </b-col>
        <b-col cols="6" md="2">
          <a href="https://github.com/Cole9712" target="_blank"
            ><img
              src="https://img.icons8.com/fluent-systems-filled/100/000000/github.png"
          /></a>
        </b-col>
        <b-col cols="6" md="2">
          <a
            href="https://www.linkedin.com/in/hao-wu-557693172/"
            target="_blank"
            ><img src="https://img.icons8.com/metro/104/000000/linkedin.png"
          /></a>
        </b-col>
        <b-col cols="6" md="2">
          <a href="mailto:colewu9712@gmail.com" target="_blank"
            ><img
              src="https://img.icons8.com/material/100/000000/upload-mail.png"
          /></a>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <p class="text-center">© 2021-2022 Hao (Cole) Wu</p>
          <p class="text-center font-italic">Last updated: Dec 01, 2022</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Footer",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
a img {
  display: block;
  margin: 0.5em auto;
  max-width: 4em;
}

* {
  background-color: #f5f5f0;
}
</style>