<template>
  <div id="app">
    <navbar />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <foot id="contact"/>
  </div>
</template>

<script>
import navbar from '@/components/NavBar.vue'
import foot from '@/components/Footer.vue'

export default {
  name:'app',
  components: {
    navbar,
    foot,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#app {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}
</style>
