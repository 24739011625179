<template>
  <div class="home">
    <hero/>
    <ability id="skills" />
    <projects id="projects"/>
    <education />
  </div>
</template>

<script>
// @ is an alias to /src
import hero from '@/components/Hero.vue'
import ability from '@/components/Abilities.vue'
import projects from '@/components/Projects.vue'
import education from '@/components/Education.vue'

export default {
  name: 'Home',
  components: {
    hero,
    ability,
    projects, 
    education,
  }
}
</script>

<style scoped>

</style>
