<template>
  <div>
    <Particles
      id="tsparticles"
      :options="{
        autoPlay: true,
        background: {
          color: {
            value: '#0d47a1',
          },
          opacity: 0,
        },
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'canvas',
          events: {
            onClick: {
              enable: true,
              mode: 'push',
            },
            onHover: {
              enable: true,
              mode: 'grab',
              parallax: {
                enable: true,
                force: 80,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: 'random',
          },
          links: {
            color: 'random',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }"
    />
    <b-container id="container">
      <div class="row h-75"> 
        <textra id="textra" class="mx-2 my-auto col text-center" :data='introWords' :timer="1" filter="bottom-top" :infinite='true' />
      </div>
      
      
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Hero",
  created() {},
  data() {
    return {
      introWords: ["Hi", "I AM", "COLE"]
    };
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

#tsparticles {
  position: absolute;
  width: 100vw;
  height: 90vh;
  background-image: url("../assets/hero-bg.png");
  background-repeat: no-repeat;
  background-size: 2251px 2246px;
}

#textra {
  font-family: 'Permanent Marker', cursive;
  font-size: 7em;
}

#container {
  height:90vh!important;
}

</style>