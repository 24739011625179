<template>
  <div id="root1">
    <b-container class="py-5">
      <b-row>
        <b-col>
          <h2 class="text-center">PROJECTS</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="project in projects"
          :key="project"
          lg="4"
          md="6"
          sm="12"
          class="my-3"
        >
          <b-card
            no-body
            :img-src="project.imgsrc"
            img-alt="Image"
            style="max-width: 25rem"
            img-top
          >
            <b-card-body>
              <b-card-title>{{ project.title }} </b-card-title>
              <b-card-sub-title> {{ project.subtitle }} </b-card-sub-title>
            </b-card-body>
            <b-list-group v-for="c in project.content" :key="c" flush>
              <b-list-group-item> {{ c }} </b-list-group-item>
            </b-list-group>
            <b-card-body>
              <b-button
                :href="project.sourceCode"
                pill
                variant="outline-primary"
                target="_blank"
                class="mr-2 my-0"
                :disabled="project.hasSourceCode ? false : true"
                >Source Code <ion-icon name="logo-github"></ion-icon
              ></b-button>
              <b-button
                :href="project.report"
                v-if="project.hasReport"
                pill
                variant="success"
                target="_blank"
                :disabled="project.hasSourceCode ? false : true"
                download
                >Project Report
              </b-button>
            </b-card-body>

            <template #footer>
              <small class="text-muted">{{ project.time }}</small>
            </template>
          </b-card></b-col
        >
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Projects",
  created() {},
  data() {
    return {
      projects: [
        {
          imgsrc: require("../assets/demos/ov.png"),
          title: "Nvidia Omniverse Digital Twin Practices",
          subtitle:
            "A project that construct scenes and extract synthetic data using Nvidia Omniverse to train neural networks.",
          // content: [
          //   "Collaborated with OneCup AI to build real-world barn simulation through Nvidia Omniverse Create, increasing neural network training efficiency.",
          //   "Lead a team of 3 to successfully extract synthetic training data from Nvidia Omniverse Replicator through Python script.",
          //   "Trained neural network using Yolo to detect animals’ names and species to prove the quality of extracted synthetic training data."
          // ],
          time: "Nov 2021 - Dec 2021",
        },
        {
          imgsrc: require("../assets/demos/mix-n-match.png"),
          title: "Chair Mix-N-Match",
          subtitle:
            "An automated 3D mix-n-match based on 3D chair part assembly modelling to generate plausible new chairs.",
          // content: [
          //   "Engineered a mixer using Python that takes 3D chairs as input and generates new chairs through recomposing parts and geometric transformations.",
          //   "Organized united API of different parts of project including parser, mixer and scorer to successfully complete project on time.",
          // ],
          time: "Nov 2021 - Dec 2021",
          hasSourceCode: true,
          sourceCode: "https://github.com/gerardogandeaga/Chair-Mix-N-Match",
        },
        {
          imgsrc: require("../assets/demos/cradle.png"),
          title: "iCradle",
          subtitle:
            "A System that supports health care delivery for pregnant women in the Bidi Bidi refugee settlement in Uganda.",
          // content: [
          //   "Under supervising of Dr.Brian to fix bugs and add new features based on previous alpha build version.",
          //   "Doing full-stacked development on back-end(Python), front-end(React) and Android App to meet customer's requirement.",
          //   "Implemented a timely request system to reduce data usage for front-end users.",
          //   "Tested and fixed bugs on Android App.",
          // ],
          time: "Sept 2021 - Dec 2021",
        },
        {
          imgsrc: require("../assets/demos/peregrine.png"),
          title: "Distributed Peregrine",
          subtitle:
            "Peregrine is a multi-threaded graph mining system, my team's goal is to modify its API and use it to run on distributed system",
          // content: [
          //   "Implemented distributed application for pattern count and FSM search.",
          //   "Achieved decent workload balance through dynamic task mapping.",
          //   "Used ZeroMQ for inter-process communication and fault handling.",
          //   "Did performance test in each iteration to find optimal performance solution.",
          // ],
          hasReport: true,
          report: "/files/peregrine_report.pdf",
          hasSourceCode: true,
          sourceCode: "https://github.com/Cole9712/peregrine",
          time: "Nov 2021 - Dec 2021",
        },
        {
          imgsrc: require("../assets/demos/persona.png"),
          title: "Project Persona",
          subtitle:
            "A web application for your digital remedy fighting against big data for improved cyber privacy with disposable 'personas'.",
          // content: [
          //   "Worked in an agile team to design and develop a web application composed of a SMTP server, front-end client and serveral back-end microservices",
          //   "Mainly focused on project architecture structure design and front-end development",
          //   "Used Firebase login API and MongoDB Atlas to manage user data",
          //   "Integrated GitHub Actions to automate Docker container build and deploy process.",
          // ],
          hasReport: true,
          report: "/files/persona_report.pdf",
          hasSourceCode: true,
          sourceCode: "https://github.com/project-persona",
          time: "July 2021 - Aug 2021",
        },
        {
          imgsrc: require("../assets/demos/LLVM.png"),
          title: "SWAR Pass for LLVM",
          subtitle:
            "An LLVM Pass that implements several SWAR instructions(binary and horizontal) that support arbitrary vectors.",
          // content: [
          //   "Worked with a graduated student to design the project and find optimal algorithm for different instructions",
          //   "Implmented binary vertical instructions like Addition, Subtraction, Multiplication, Division, Remainder",
          //   "Supported horizontal instructions like Population Count and Bitscan",
          //   "Evaluated Correctness and Performance to compare with LLVM default program",
          // ],
          hasReport: true,
          report: "/files/489report.pdf",
          hasSourceCode: true,
          sourceCode: "https://github.com/Aiaid/llvm-swar",
          time: "June 2021 - Aug 2021",
        },
        {
          imgsrc: require("../assets/demos/protocol.png"),
          title: "Custom Reliable Data Transfer Protocol",
          subtitle:
            "A file transfer protocol based on UDP using Python providing reliability, flow control and congestion control.",
          // content: [
          //   "Implmented using Python and UDP socket, but is connection-oriented",
          //   "Provide reliability, flow control and congestion control as well as Command Line Interface for both server and client",
          //   "Inclued Report which contains analysis of performance and features",
          // ],
          hasSourceCode: true,
          sourceCode:
            "https://github.com/Cole9712/Reliable-Data-Transfer-Protocol",
          time: "April 2021",
        },
        {
          imgsrc: require("../assets/demos/tetris.png"),
          title: "Tetris",
          subtitle: "A Tetris game built with WebGL.",
          // content: [
          //   "Implmented using JS and Webgl",
          //   "Contains 7 types of shapes",
          //   "Features like row removing and scoring system",
          // ],
          hasSourceCode: true,
          sourceCode: "https://github.com/Cole9712/Tetris",
          time: "March 2021",
        },
        {
          imgsrc: require("../assets/demos/doge.png"),
          title: "Crypto Doge",
          subtitle:
            "A Discord bot created during hackathon that allows users to interact and get real time cryptocurrency information with simple commands.",
          // content: [
          //   "Basic informations about the cryptocurrency, such as name, logo, current price, price change, etc.",
          //   "Detailed description about the cryptocurrency",
          //   "Visual representation of the recent market price chart",
          // ],
          hasSourceCode: true,
          sourceCode: "https://github.com/Cole9712/crypto_doge",
          time: "February 2021",
        },
        {
          imgsrc: require("../assets/demos/facial.gif"),
          title: "Facial Decection Web System",
          subtitle:
            "This ployglot project created a web system that can perform facial detection and face swapping from user uploaded images.",
          // content: [
          //   "Used Vue as front-end framework and Go as back-end server ",
          //   "Implemented facial detection and face swapping using Python with openCV. Processed users’ uploaded image to detect face or swap face",
          //   "Used Pixabay.com API to let users be able to search images online and perform facial detection on them",
          // ],
          hasSourceCode: true,
          sourceCode: "https://git.io/JLXyg",
          time: "Nov 2020 - Dec 2020",
        },
        {
          imgsrc: require("../assets/demos/calories.png"),
          title: "Daily Calories and Nutrition Calculator",
          subtitle:
            "Used Java and Android Studio to create an Android Application to record users’ daily calories intake. ",
          // content: [
          //   "Constructed SQLite database with users’ input data to record users’ meals",
          //   "Generated current nutritional and calories intake and needs based on user’s meal input",
          //   "Integrated Canadian Nutrient File into data base to let users search food from built-in database.",
          // ],
          hasSourceCode: true,
          sourceCode:
            "https://github.com/Cole9712/Reliable-Data-Transfer-Protocol",
          time: "Sept 2020 - Dec 2020",
        },
        {
          imgsrc: require("../assets/demos/276.jpg"),
          title: "Lost Memories (Arcade-style 2D game)",
          subtitle:
            "Used Java with Maven to develop a PC game which the player controls a main character to get his lost memories, in order to pass the game, the player should avoid barriers and chasing enemies. ",
          // content: [
          //   "Implemented A* Search Algorithm to let enemy chase the main character",
          //   "Developed graphical user inference of the gaming using Java Swing and Canvas to improve user experience visually",
          // ],
          hasSourceCode: false,
          sourceCode: "",
          time: "Jan 2020 - Apr 2020",
        },
      ],
    };
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
#projects {
  background-color: #f5f5f0;
}

ion-icon {
  font-size: 120%;
}
</style>