import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'

// external components
import Particles from "particles.vue";
import Textra from 'vue-textra';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

Vue.use(Particles);
Vue.use(Textra);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

